import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useRef } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

// Import each section component (you'll implement these separately)
import HeroSection from "../components/HeroSection";
import OurClients from "../components/OurClients";
import ReviewsSection from "../components/ReviewsSection";
import SideImageRight from "../components/SideImageRight";
import SideImageLeft from "../components/SideImageLeft";
import CustomersWeWorkWith from "../components/CustomersWeWorkWith";
import ItemsSection from "../components/ItemsSection";
import TextSection from "../components/TextSection";
import FaqsSection from "../components/FaqsSection";
import MapSection from "../components/MapSection";
import FormSection from "../components/FormSection";

const IndexPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			wpAdPage(title: { eq: "Main" }) {
				adPageFields {
					components {
						... on WpAdPage_Adpagefields_Components_HeroSection {
							body
							fieldGroupName
							heading
							numberOfReviews
							button {
								url
								title
							}
							backgroundImage {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpAdPage_Adpagefields_Components_OurClients {
							fieldGroupName
							logos {
								image {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						... on WpAdPage_Adpagefields_Components_Reviews {
							fieldGroupName
							selectedReviews {
								... on WpReview {
									id
									reviewFields {
										name
										review
									}
								}
							}
						}
						... on WpAdPage_Adpagefields_Components_SideImageRight {
							body
							fieldGroupName
							heading
							button {
								title
								url
							}
							image {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpAdPage_Adpagefields_Components_CustomersWeWorkWith {
							fieldGroupName
							heading
							button {
								title
								url
							}
							customers {
								heading
								image {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						... on WpAdPage_Adpagefields_Components_SideImageLeft {
							body
							fieldGroupName
							heading
							button {
								title
								url
							}
							image {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpAdPage_Adpagefields_Components_ItemsSection {
							fieldGroupName
							items {
								heading
								body
							}
						}
						... on WpAdPage_Adpagefields_Components_TextSection {
							body
							fieldGroupName
							heading
							button {
								title
								url
							}
						}
						... on WpAdPage_Adpagefields_Components_FaqsSection {
							fieldGroupName
							faqs {
								... on WpFaq {
									id
									faqFields {
										answer
										question
									}
								}
							}
						}
						... on WpAdPage_Adpagefields_Components_MapSection {
							fieldGroupName
							heading
						}
						... on WpAdPage_Adpagefields_Components_FormSection {
							fieldGroupName
							heading
							backgroundImage {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	// Renderer for flexible layout sections
	const renderComponent = (component) => {
		switch (component.fieldGroupName) {
			case "AdPage_Adpagefields_Components_HeroSection":
				return (
					<HeroSection
						heading={component.heading}
						body={component.body}
						numberOfReviews={component.numberOfReviews}
						button={component.button}
						backgroundImage={component.backgroundImage}
						adPage="main"
					/>
				);
			case "AdPage_Adpagefields_Components_OurClients":
				return <OurClients logos={component.logos} />;
			case "AdPage_Adpagefields_Components_Reviews":
				return <ReviewsSection selectedReviews={component.selectedReviews} />;
			case "AdPage_Adpagefields_Components_SideImageRight":
				return (
					<SideImageRight
						heading={component.heading}
						body={component.body}
						button={component.button}
						image={component.image}
					/>
				);
			case "AdPage_Adpagefields_Components_SideImageLeft":
				return (
					<SideImageLeft
						heading={component.heading}
						body={component.body}
						button={component.button}
						image={component.image}
					/>
				);
			case "AdPage_Adpagefields_Components_CustomersWeWorkWith":
				return (
					<CustomersWeWorkWith
						heading={component.heading}
						button={component.button}
						customers={component.customers}
					/>
				);
			case "AdPage_Adpagefields_Components_ItemsSection":
				return <ItemsSection items={component.items} />;
			case "AdPage_Adpagefields_Components_TextSection":
				return (
					<TextSection
						heading={component.heading}
						body={component.body}
						button={component.button}
					/>
				);
			case "AdPage_Adpagefields_Components_FaqsSection":
				return <FaqsSection faqs={component.faqs} />;
			case "AdPage_Adpagefields_Components_MapSection":
				return <MapSection heading={component.heading} />;
			case "AdPage_Adpagefields_Components_FormSection":
				return (
					<FormSection
						adPage="main"
						heading={component.heading}
						backgroundImage={component.backgroundImage}
					/>
				);
			default:
				return null; // Return null if the component type is not handled
		}
	};

	return (
		<>
			<GatsbySeo
				title="Capture Every Detail with High-Resolution Surveys"
				nofollow
				noindex
			/>

			<Layout>
				{data.wpAdPage.adPageFields.components.map((component, index) => (
					<div key={index}>{renderComponent(component)}</div>
				))}
			</Layout>
		</>
	);
};

export default IndexPage;
